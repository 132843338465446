import CheckIcon from "@material-design-icons/svg/round/check_circle.svg";
import ErrorIcon from "@material-design-icons/svg/round/error.svg";
import InfoIcon from "@material-design-icons/svg/round/info.svg";
import WarningIcon from "@material-design-icons/svg/round/warning.svg";
import { Snackbar } from "@material-ui/core";
import { AlertProps } from "@material-ui/lab";
import classnames from "classnames";
import * as React from "react";
import { A } from "ts-toolbelt";

import { ModalCloseButton } from "~/components/core/Modal/ModalCloseButton";

type SnackData = {
  severity: AlertProps["severity"];
  message: string;
  visible: boolean;
};

type ShowSnack = A.Compute<
  {
    [K in NonNullable<SnackData["severity"]>]: (
      message: SnackData["message"]
    ) => void;
  }
>;

function makeProxy(fn: (data: Omit<SnackData, "visible">) => void): ShowSnack {
  return {
    info: (message: SnackData["message"]) => fn({ message, severity: "info" }),
    success: (message: SnackData["message"]) =>
      fn({ message, severity: "success" }),
    error: (message: SnackData["message"]) =>
      fn({ message, severity: "error" }),
    warning: (message: SnackData["message"]) =>
      fn({ message, severity: "warning" })
  };
}

const SnackContext = React.createContext<ShowSnack>(makeProxy(() => undefined));

export function useShowSnack(): ShowSnack {
  return React.useContext(SnackContext);
}

export const SnackLayout: React.FC = ({ children }) => {
  const [snackData, setSnackData] = React.useState<SnackData>({
    severity: "success",
    message: "",
    visible: false
  });

  const closeSnackbar = React.useCallback(() => {
    setSnackData(data => ({
      ...data,
      visible: false
    }));
  }, []);

  const showSnackbar = React.useMemo(
    () =>
      makeProxy((data: Omit<SnackData, "visible">) =>
        setSnackData({
          ...data,
          visible: true
        })
      ),
    []
  );

  return (
    <>
      <SnackContext.Provider value={showSnackbar}>
        {children}
      </SnackContext.Provider>
      <Snackbar
        open={snackData.visible}
        autoHideDuration={6_000}
        onClose={closeSnackbar}
        anchorOrigin={{
          horizontal: "center",
          vertical: "top"
        }}
      >
        {snackData.severity && (
          <div className={classnames(SnackVariants[snackData.severity].base)}>
            <div className="flex gap-[8px] items-center">
              {SnackVariants[snackData.severity].icon}
              <div className="inputLabel">{snackData.message}</div>
            </div>
            <ModalCloseButton onClose={closeSnackbar} classname="mb-0" />
          </div>
        )}
      </Snackbar>
    </>
  );
};

const base =
  "p-[16px] flex gap-[16px] justify-between min-w-[400px] shadow-default rounded-[4px] border paragraph";
const iconBase = "w-[24px] h-[24px] shrink-0 fill-current";

const SnackVariants = {
  success: {
    base: classnames(base, "bg-newGrowth-100 border-newGrowth-500"),
    icon: (
      <CheckIcon
        className={classnames(iconBase, "text-newGrowth-700")}
        aria-hidden
      />
    )
  },
  warning: {
    base: classnames(base, "bg-warning-100 border-warning-500"),
    icon: (
      <WarningIcon
        className={classnames(iconBase, "text-warning-500")}
        aria-hidden
      />
    )
  },
  error: {
    base: classnames(base, "bg-redApple-100 border-redApple-500"),
    icon: (
      <ErrorIcon
        className={classnames(iconBase, "text-redApple-800")}
        aria-hidden
      />
    )
  },
  info: {
    base: classnames(base, "bg-lunchboxBlue-100 border-lunchboxBlue-500"),
    icon: (
      <InfoIcon
        className={classnames(iconBase, "text-lunchboxBlue-700")}
        aria-hidden
      />
    )
  }
};
