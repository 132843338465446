export const API = {
  auth: {
    jwtRefresh: "/api/v1/auth/jwt/refresh/",
    passwordResetGetToken: "/api/v1/account/passwd_reset/"
  },
  lessons: {
    labTutorFeedback: "/api/v1/lessons/writing_lab_tutor_assignment/"
  },
  students: {
    card: "/api/v1/student/card/",
    bonusMinutes: "/api/v1/student/bonus_minutes/",
    subjectsTaken: "/api/v1/student/subjects_taken/",
    addMinutes: "/api/v1/student/add_bonus_minutes/",
    totalReviews: "/api/v1/student/total_reviews/"
  }
};

export const API_URL: string = (() => {
  return process.env.NEXT_STATIC_API_URL || "";
})();

export const PEAR_DECK_PSI_URL: string = (() => {
  return process.env.NEXT_PEARDECK_PSI_SCRIPT_URL || "";
})();

export const PEAR_TERMS_URL =
  "https://www.peardeck.com/policies/product-terms-and-end-user-license-agreement";
export const PEAR_PRIVACY_URL =
  "https://www.peardeck.com/policies/privacy-policy-for-product-users";
